<div class="modal fade" id="AiInsightsModal" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        Full Summaries
      </div>
      <div class="modal-body">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item" *ngFor="let yearAccount of financials; let isFirst=first"
            style="cursor: pointer">
            <a class="nav-link" data-toggle="tab" role="tab"
              [href]="'#insights' + yearAccount.id"
              [attr.aria-controls]="'aiSummary' + yearAccount.id" [attr.aria-selected]="isFirst"
              [ngClass]="{'active': isFirst}">
              Y.E. {{ yearAccount.made_up_to | date:'d MMM y' }}
            </a>
          </li>
        </ul>

        <div class="tab-content" style="width: 100%">            
        
          <div class="tab-pane" role="tabpanel" id="insights{{ yearAccount.id }}"
            *ngFor="let yearAccount of financials; let isFirst=first" [ngClass]="{'active' : isFirst, 'show' : isFirst}"> 
            <ng-container *ngIf="true">

              <ai-accounts-summaries [summariesYearAccount]="yearAccount.id" [aiAccountsSummaries]="aiAccountsSummaries" [isInModal]="true" [companyId]="companyId">
              </ai-accounts-summaries>
            </ng-container>           
          </div>
        </div>
      </div>
    </div>
  </div>
</div>