import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs';

@Injectable()
export class DataSharingService {
  private dataSourceTagToDelete = new BehaviorSubject<any>('');
  currentSelectedTagIdToDelete = this.dataSourceTagToDelete.asObservable();

  private dataSourceSelectedCurrentCompanyIds = new BehaviorSubject<any>([]);
  currentSelectedCompanyIds =
    this.dataSourceSelectedCurrentCompanyIds.asObservable();

  private dataSourceSelectedCurrentSearchParams = new BehaviorSubject<any>(
    new HttpParams()
  );
  currentSelectedSearchParams =
    this.dataSourceSelectedCurrentSearchParams.asObservable();

  private zgResultCountSource = new BehaviorSubject<number>(0);
  currentZgResultCount: Observable<number> =
    this.zgResultCountSource.asObservable();

  constructor() {}

  changeDataSourceCurrentCompanyIds(inputData: any) {
    this.dataSourceSelectedCurrentCompanyIds.next(inputData);
    // We don't actually want to clear these HTTP Params as the query is required for downloading to add ES Colums
    // this.dataSourceSelectedCurrentSearchParams.next(new HttpParams());
    this.dataSourceTagToDelete.next('');
  }

  updateJustCurrentSearchParams(searchParams: any) {
    this.dataSourceSelectedCurrentSearchParams.next(searchParams);
  }

  changeDataSourceCurrentSearchParams(searchParams: any) {
    this.dataSourceSelectedCurrentSearchParams.next(searchParams);
    this.dataSourceSelectedCurrentCompanyIds.next([]);
    this.dataSourceTagToDelete.next('');
  }

  changeDataSourceTagIdToDelete(tagIdToDelete: any) {
    this.dataSourceTagToDelete.next(tagIdToDelete);
  }

  clearTagToDelete() {
    this.dataSourceTagToDelete.next('');
  }

  updateZgResultCount(count: number) {
    this.zgResultCountSource.next(count);
  }
}
