<div *ngFor="let importExportMonth of inputParams.detailedImportsOrExports">
    <h5>{{ importExportMonth[0] | date: 'MMMM yyyy'}}</h5>
    <table class="table table-striped">
        <tbody>
            <tr *ngFor="let importExportDetails of importExportMonth[1]">
                <td style="text-align: left">
                    <p [innerHTML]="importExportDetails.description"></p>
                </td>
                <td style="vertical-align: middle" *ngIf="importExportDetails.value">
                    <span class="badge badge-info" style="text-align: left; display: inline">Estimated Value: {{ importExportDetails.value.toPrecision(3) | currency:'GBP':'symbol-narrow':'.0' }}</span>
                    <div style="display: inline" matTooltipPosition="above" matTooltip="By comparing to other organisations that have imported or exported similar products, we are able to calculate an approximate figure for the total monetary value of these imported or exported products in this month. Please note that this figure is an estimate."><i class="fal fa-question-circle" style="margin-left: 5px; font-size: 14px;"></i></div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
