<!-- THIS IS THE OLD ZINT GROW UI DO NOT UPDATE! -->
<div class="row">    
    <div class="col-md-8 col-sm-12" [ngClass]="isMarkedCompleted ? 'read-notifications' : null">
        <h5 [ngClass]="{'mb-0': isSuperuser}"><a class="text-reset"
                [href]="'https://app.zint.io/company/' + group.company_number" target="_blank">
                {{group.company_name}}
                <i class="fal fa-external-link fa-xs" matTooltip="company page on Zint" matTooltipPosition="above"></i>
            </a>
        </h5>
        <span *ngIf="isSuperuser" class="small font-italic">Team member tracking - {{group.email}}</span>
    </div>
    <div class="col-md-4 col-sm-12 mb-sm-1">
        <div class="d-flex justify-content-start justify-content-md-end">
            <button class="basic-button-base button-bear btn-sm"
                [ngClass]="isMarkedCompleted ? 'button-bear-selected' : null" matTooltip="mark as read"
                matTooltipPosition="above" (click)="markAsCompleted(group.id)"><i
                    class="fal fa-clipboard-check"></i></button>
            <button class="basic-button-base button-purple btn-sm mx-1"
                [ngClass]="isUseful ? 'button-purple-selected' : null" matTooltip="mark as useful"
                matTooltipPosition="above" (click)="toggleUseful(group.id)"><i class="fal fa-thumbs-up"></i></button>
            <button class="basic-button-base button-orange" [ngClass]="isInaccurate ? 'button-orange-selected' : null"
                matTooltip="report issues/inaccuracies" matTooltipPosition="above" (click)="reportIssues()"><i
                    class="fal fa-comment-alt-exclamation"></i></button>
            <button role="link" class="basic-button-base button-dark btn-sm ml-1"
                [ngClass]="isSharing ? 'button-dark-selected' : null" matTooltip="share this notification"
                matTooltipPosition="above" (click)="share()"><i class="fal fa-share-square"></i></button>
        </div>
        <div *ngIf="isInaccurate" class="float-left float-md-right position-relative">
            <input type="text" maxlength="180" class="pr-4" placeholder="enter your comment"
                (keyup.enter)="submitReport(group.id, $event.target.value)" />
            <span class="input-report-suffix">&#9166;</span>
        </div>
    </div>
</div>
<hr class="no-margin-top">
<news-search *ngIf="group.event_source_type === 'NewsSearchEventSourceType'" [notifications]="group.notifications"
    [markedAsRead]="isMarkedCompleted"></news-search>
<officer *ngIf="group.event_source_type === 'OfficerEventSourceType'" [notifications]="group.notifications"
    [markedAsRead]="isMarkedCompleted"></officer>
<psc *ngIf="group.event_source_type === 'PSCEventSourceType'" [notifications]="group.notifications"
    [markedAsRead]="isMarkedCompleted"></psc>
<accounts-search *ngIf="group.event_source_type === 'AccountsSearchEventSourceType'"
    [notifications]="group.notifications" [markedAsRead]="isMarkedCompleted"></accounts-search>
<accounts-filing *ngIf="group.event_source_type === 'AccountsFilingEventSourceType'"
    [notifications]="group.notifications" [markedAsRead]="isMarkedCompleted"></accounts-filing>
<website-search *ngIf="group.event_source_type === 'WebsiteSearchEventSourceType'" [notifications]="group.notifications"
    [markedAsRead]="isMarkedCompleted"></website-search>
<hiring *ngIf="group.event_source_type === 'HiringEventSourceType'" [notifications]="group.notifications"
    [markedAsRead]="isMarkedCompleted"></hiring>
<import-export *ngIf="group.event_source_type === 'ImportExportEventSourceType'" [notifications]="group.notifications"
    [markedAsRead]="isMarkedCompleted"></import-export>
<related-officer *ngIf="group.event_source_type === 'RelatedOfficerEventSourceType'"
    [notifications]="group.notifications" [markedAsRead]="isMarkedCompleted"></related-officer>
<corporate-structure *ngIf="group.event_source_type === 'CorporateStructureEventSourceType'"
    [notifications]="group.notifications" [markedAsRead]="isMarkedCompleted"></corporate-structure>
<related-psc *ngIf="group.event_source_type === 'RelatedPSCEventSourceType'" [notifications]="group.notifications"
    [markedAsRead]="isMarkedCompleted"></related-psc>