<main class="container">
  <header>
    <h2>Calendar Integration</h2>
    <div class="mt-5">
      <p>Our Calendar Integration feature allows you to generate AI-powered preparatory notes of your upcoming meetings
        and view them in one place.</p>
      <hr>
      <ng-container *ngIf="hasAcceptedTermsAndConditions">
        <ng-container *ngIf="!isMicrosoftAccountAuthorised && !isGoogleAccountAuthorised">
          <a role="button" class="btn basic-button-base button-dark" [attr.href]="googleOauthLink"
            matTooltip="Login with Google" matTooltipPosition="above">
            <i class="fab fa-google"></i>
            Grant Calendar Access
          </a>
        </ng-container>
        <br><br>
        <ng-container *ngIf="!isMicrosoftAccountAuthorised && !isGoogleAccountAuthorised">
          <a role="button" class="btn basic-button-base button-dark" [attr.href]="microsoftOauthLink"
            matTooltip="Login with Microsoft" matTooltipPosition="above">
            <i class="fab fa-microsoft"></i>
            Grant Calendar Access
          </a>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="!hasAcceptedTermsAndConditions">
        <p>By continuing, you accept the additional additional <a href="#termsForCalendarIntegration"
            data-toggle="modal" data-target="#termsForCalendarIntegration">Terms and Conditions</a> of this additional
          functionality.</p>
        <button class="basic-button-base button-dark" (click)="hasAcceptedTermsAndConditions=true">
          Continue
        </button>
      </ng-container>
    </div>
  </header>

  <section *ngIf="isGoogleAccountAuthorised || isMicrosoftAccountAuthorised" class="my-5">
    <h5>{{calendarSummaries.length ? 'Here are your upcoming meetings we have been able to generate summaries for.' :
      'Your meeting summaries will appear here soon.'}}</h5>
    <details *ngIf="summariesToDisplay.length" class="ml-2">
      <summary class="font-weight-bold w-75">Meetings Index</summary>
      <ul class="list-group list-group-flush w-75 mb-0">
        <li *ngFor="let summary of summariesToDisplay" class="list-group-item index-container"
          (click)="jumpToMeeting(summary.event_id)">
          <a class="meetings-index">{{summary.title}}</a>
        </li>
      </ul>
      <button *ngIf="!noMoreData" class="basic-button-base button-dark ml-2" (click)="loadMoreData()">load more
        meetings</button>
    </details>
    <div *ngFor="let summary of summariesToDisplay" class="my-4">
      <h3 class="meeting-title" id="{{summary.event_id}}">{{summary.title}}<a
          href="https://app.zint.io/company/{{summary.company_number}}" target="_blank" class="zint-link ml-2"
          matTooltip="view company on Zint" matTooltipPosition="above"><i class="far fa-external-link"></i></a></h3>
      <p>Scheduled time: {{summary.start_datetime | date:'medium'}} -
      <a href="{{summary.link}}" target="_blank"> View in
        Calendar<i class="fal fa-external-link fa-xs ml-1"></i></a>
      </p>
      <!-- Prep notes -->
      <details class="mt-2">
        <summary class="font-weight-bold">Preparation Notes: </summary>
        <ng-container *ngIf="summary.preparation_notes">
            <ng-container *ngFor="let prepSection of summary.preparation_notes">
              <h4 [innerHTML]="prepSection.section_title"></h4>
              <p [innerHTML]="prepSection.pain_point"></p>
              <p [innerHTML]="prepSection.our_solution"></p>
            </ng-container>
        </ng-container>
        <button (click)="scrollToTop()" matTooltip="scroll to top" matTooltipPosition="after"
          class="btn btn-sm btn-outline-dark"><i class="fal fa-arrow-to-top"></i></button>
      </details>
      <!-- Website summary -->
      <details *ngIf="summary.website_summary" class="mt-2">
        <summary class="font-weight-bold">Website Summary: </summary>
        <pre>{{processWebsiteSummary(summary.website_summary)}}</pre>
        <button (click)="scrollToTop()" matTooltip="scroll to top" matTooltipPosition="after"
          class="btn btn-sm btn-outline-dark"><i class="fal fa-arrow-to-top"></i></button>
      </details>
      <!-- Accounts summary -->
      <details *ngIf="summary.accounts_summary" class="mt-2">
        <summary class="font-weight-bold">Accounts Summary: </summary>
        <div class="bg-light">
          <div *ngFor="let category of summary.accounts_summary.bullets | keyvalue">
            <span class="badge badge-info">{{category.key}}</span>
            <br>
            <ul>
              <li *ngFor="let bullet of category.value">
                {{bullet.text}}
              </li>
            </ul>
          </div>
        </div>
        <button (click)="scrollToTop()" matTooltip="scroll to top" matTooltipPosition="after"
          class="btn btn-sm btn-outline-dark"><i class="fal fa-arrow-to-top"></i></button>
      </details>
    </div>
  </section>
  <footer class="my-5">
    <p class="text-center text-muted">To revoke access to your Google calendar and unsubscribe from all future notifications click <a href="https://myaccount.google.com/connections" target="_blank">here</a>.</p>
      <p class="text-center text-muted">Please login to your Microsoft account to revoke access there.</p>
  </footer>
</main>

<div class="modal fade" id="termsForCalendarIntegration" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="customColumnEditModalLabel">Terms and Conditions for Enabling Calendar Integration
          Features</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h1>Terms &amp; Conditions</h1>
        <p>This agreement is made between you, the user, your employer where applicable, and Zint Technology Limited
          ("Zint", "we", "us", or "our"). It covers the use of our additional software functionality that provides us
          access to your Google or Microsoft account calendar ("Calendar Functionality"). It stipulates the terms and
          conditions related to data usage, storage, liability, and other pertinent areas.</p>

        <h2>1. DATA COLLECTION AND USE</h2>

        <p>1.1 We will request read-only access to your calendar data, which includes extraction of the domain names of
          any upcoming event attendees, event titles, event dates and times and event unique identifiers.</p>

        <p>1.2 The domain names collected will be saved in our database and used to generate an Artificial
          Intelligence-powered summaries and contribute to our information pool of known domain names.</p>

        <p>1.3 We uphold a strict policy of not storing email addresses or personal contact information of attendees of
          your calendar events under any circumstances.</p>

        <p>1.4 We utilise OpenAI's APIs for the purpose of AI-driven summary creation. As such, users must familiarise
          themselves with OpenAI's terms and conditions and data usage policies.</p>

        <h2>2. CONFIDENTIALITY AND PRIVACY</h2>

        <p>2.1 We respect the privacy of our users and abide by all applicable UK data protection and privacy laws.</p>

        <p>2.2 Collection, storage, and processing of your data will be undertaken in compliance with our Privacy Policy
          and with strict adherence to the principles of data minimization and data protection.</p>

        <h2>3. LIABILITY</h2>

        <p>3.1 Zint will not assume any responsibility or liability for any loss or damage that may occur from use of
          our Calendar Functionality. Our platform is provided on an 'as is' and 'as available' basis, and users assume
          full responsibility and risk for its use.</p>

        <p>3.2 In no event shall Zint Technology Limited or its officers, directors or employees be liable for any
          indirect, incidental, special, exemplary, or consequential damages.</p>

        <h2>4. CONSENT</h2>

        <p>4.1 By granting read-only access to your calendar for the Calendar Functionality, you accept these additional
          terms and conditions, and agree to their implementation with regard to your data.</p>

        <p>This agreement is additional to and separate from any wider use agreement of our platform.</p>

        <p>Please review and consider these terms carefully before accepting them and granting the necessary
          permissions. By doing so, you are acknowledging your comprehensive understanding and agreement. If you do not
          agree to these terms, we respectfully ask that you refrain from using the Calendar Functionality.</p>

        <h2>5. CHANGES TO TERMS</h2>

        <p>5.1 We reserve the right to modify or update these Terms at any time. We will notify you of any material
          changes by posting the updated Terms. Your continued use of the calendar integration features after any such
          changes constitutes your acceptance of the new Terms.</p>

        <h2>6. GOVERNING LAW AND JURISDICTION</h2>

        <p>6.1 These Terms shall be governed by and construed in accordance with the laws of England and Wales. Any
          disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of
          the courts of England and Wales.</p>
      </div>
    </div>
  </div>
</div>
