<div class="row" [ngClass]="markedAsRead ? 'read-notifications' : null">
    <div class="col-md-8">
        <h5>
            {{event_metadata.article_title}}
        </h5>
        <hr>
        <div *ngFor="let notification of notifications">
            <div notification-search-term
                 [notification]="notification"
                 [searchTermHTML]="notification.event_metadata.preview"
                 [searchTermLink]="'/company/' + companyNumber + (notification.event_metadata.news_source === 'self_published' ? '?selfPublishedCompanyNewsSearch=' : '?companyNewsSearch=') + notification.event_metadata.search_term"
                 [searchTermString]="' identified in article.'"
                 [searchTerm]="notification.event_metadata.search_term"
                 ></div>
        </div>
        <div class="row small-margin-top">
            <div class="col-md-12">
                <a href="{{event_metadata.article_link}}" target="_blank">
                    <span class="badge badge-link">
                        <i class="fal fa-external-link"></i>
                        &nbsp;View article
                    </span>
                </a>
            </div>
        </div>
    </div>
    <div class="align-self-center" right-image
        [imageLink]="'https://images.unsplash.com/photo-1504711434969-e33886168f5c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3270&q=80'"
        [htmlBelowImage]="htmlBelowImage"
    ></div>
</div>
