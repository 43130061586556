import { IConfigSlider } from '../classes/custom-range-slider';

export const heatScore: IConfigSlider[] = [
  {
    min: 0,
    max: 100,
    label: 'HeatScore',
    postfix: '%',
  },
];
