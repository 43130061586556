import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import { CommunicationService } from '../../services/communication.service';

@Component({
  selector: 'plan-features-enabled',
  templateUrl: './plan-features-enabled.component.html',
  styleUrls: ['./plan-features-enabled.component.css'],
})
export class PlanFeaturesEnabledComponent implements OnInit {
  constructor(
    private auth: AuthenticationService,
    private communicationService: CommunicationService
  ) {}

  ngOnInit(): void {
    this.auth
      .getPlanFeaturesEnabled()
      .subscribe(data => this.updateUserPlanFeatures(data));
  }

  updateUserPlanFeatures(response): void {
    this.communicationService.sendIsReadOnlyUserMessage(
      response.is_read_only_licence || false
    );
    this.communicationService.sendHasPropensityScoreAccessMessage(
      response.has_propensity_score_access || false
    );
    this.communicationService.sendHasSalesforceAccessMessage(
      response.has_salesforce_access || false
    );
    this.communicationService.sendHasSalesforceV2AccessMessage(
      response.has_salesforce_v2_access || false
    );
    this.communicationService.sendHasDeepCrawlAccessMessage(
      response.has_deep_crawl_access || false
    );
    this.communicationService.sendHasRecentAccountsAccessMessage(
      response.has_recent_accounts_access || false
    );
    this.communicationService.sendHasMultiplePostcodeSearchAccessMessage(
      response.has_multiple_postcode_search_access || false
    );
    this.communicationService.sendHasEnergyAndEmissionsAccessMessage(
      response.has_energy_and_emissions_access || false
    );
    this.communicationService.sendHasPatentsAccessMessage(
      response.has_patents_access || false
    );
    this.communicationService.sendHasShareFilingsAccessMessage(
      response.has_share_filings_access || false
    );
    this.communicationService.sendHasGovernmentContractsAccessMessage(
      response.has_government_contracts_access || false
    );
    this.communicationService.sendHasCompanyChargesAccessMessage(
      response.has_company_charges_access || false
    );
    this.communicationService.sendHasCompanyMatcherAccessMessage(
      response.has_company_matcher_access || false
    );
    this.communicationService.sendHasZintGrowAccessMessage(
      response.has_zint_grow_access || false
    );
    this.communicationService.sendHasCrossSellAccessMessage(
      response.has_cross_sell_access || false
    );
    this.communicationService.sendHasMarketMappingReportAccessMessage(
      response.has_market_mapping_report_access || false
    );
    this.communicationService.sendHasAIPitchBuilderAccessMessage(
      response.has_ai_pitch_builder_access || false
    );
    this.communicationService.sendHasSupplierDataAccessMessage(
      response.has_supplier_data_access || false
    );
    this.communicationService.sendHasTerritoryAssignmentAccessMessage(
      response.has_territory_assignment || false
    );
    this.communicationService.sendHasAIAccountsSummary(
      response.has_ai_accounts_summary || false
    );
    this.communicationService.sendHasSemanticSearchAccessMessage(
      response.has_semantic_search_access || false
    );
    this.communicationService.sendHasCalendarIntegrationAccessMessage(
      response.has_calendar_integration_access || false
    );
    this.communicationService.sendHasHubspotAccess(
      response.has_hubspot_access || false
    );
    this.communicationService.sendAISearchAccess(
      response.has_ai_search_url_access || false
    );
    this.communicationService.sendHasAIResearchAssistantAccess(
      response.has_ai_assistant_research_access || false
    );    
    this.communicationService.sendHasAICompanyAssistantAccess(
      response.has_ai_company_assistant_access || false
    );
  }
}
