<main class="page-container">
  <section class="header-row-container border-rounded-05 shadow-sm row align-items-center pr-0 py-2 sticky-top">
    <div class="col-12 col-sm-7 d-flex align-items-center flex-nowrap">
      <div class="result-container"><span class="">Results: {{resultCount}}</span></div>
      <div class="d-flex ml-4">
        <div *ngIf="categoryFilter" role="button" class="border border-rounded-05 p-2" [matMenuTriggerFor]="filterBy">
          <span class="mr-2">Filter by:
          </span>
          <i class="far fa-angle-down"></i>
          <mat-menu #filterBy="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="eventTypesMenu">event type</button>
            <button mat-menu-item [matMenuTriggerFor]="categoriesMenu">category</button>
            <button *ngIf="isSuperUser" mat-menu-item [matMenuTriggerFor]="usersMenu">user</button>
          </mat-menu>
          <mat-menu #eventTypesMenu="matMenu">
            <span mat-menu-item>
              <mat-checkbox disableRipple="true" [checked]="isAllSelected['eventType']" [color]="eventTypeFilter.color"
                [indeterminate]="someSelected('eventType')" (change)="selectAll($event.checked, 'eventType')"
                (click)="$event.stopPropagation()">
                {{eventTypeFilter.name}}
              </mat-checkbox>
            </span>
            <span>
              <ul class="pl-2">
                <li mat-menu-item *ngFor="let type of eventTypeFilter.types">
                  <mat-checkbox [disabled]="isLoading.loadingNotifications" disableRipple="'true'" [(ngModel)]="type.selected" [color]="eventTypeFilter.color"
                    (ngModelChange)="updateAllSelected('eventType')" (click)="$event.stopPropagation()">
                    {{type.name}}
                  </mat-checkbox>
                </li>
              </ul>
            </span>
          </mat-menu>
          <mat-menu #categoriesMenu="matMenu">
            <span mat-menu-item class="">
              <mat-checkbox disableRipple="true" [checked]="isAllSelected['category']" [color]="categoryFilter.color"
                [indeterminate]="someSelected('category')" (change)="selectAll($event.checked, 'category')"
                (click)="$event.stopPropagation()">
                {{categoryFilter.name}}
              </mat-checkbox>
            </span>
            <span class="">
              <ul class="pl-2">
                <li mat-menu-item *ngFor="let type of categoryFilter.types">
                  <mat-checkbox [disabled]="isLoading.loadingNotifications" disableRipple="'true'" [(ngModel)]="type.selected" [color]="categoryFilter.color"
                    (ngModelChange)="updateAllSelected('category')" (click)="$event.stopPropagation()">
                    {{type.name}}
                  </mat-checkbox>
                </li>
              </ul>
            </span>
          </mat-menu>

          <mat-menu #usersMenu="matMenu">
            <span mat-menu-item class="">
              <mat-checkbox disableRipple="true" [checked]="isAllSelected['user']" [color]="userFilter.color"
                [indeterminate]="someSelected('user')" (change)="selectAll($event.checked, 'user')"
                (click)="$event.stopPropagation()">
                {{userFilter.name}}
              </mat-checkbox>
            </span>
            <span class="">
              <ul class="pl-2">
                <li mat-menu-item *ngFor="let type of userFilter.types">
                  <mat-checkbox [disabled]="isLoading.loadingNotifications" disableRipple="'true'" [(ngModel)]="type.selected" [color]="userFilter.color"
                    (ngModelChange)="updateAllSelected('user')" (click)="$event.stopPropagation()">
                    {{type.name}}
                  </mat-checkbox>
                </li>
              </ul>
            </span>
          </mat-menu>
        </div>
        <div role="button" class="border border-rounded-05 ml-4 p-2 " [matMenuTriggerFor]="sortBy">
          <span class="mr-2">Sort by: {{activeSorting}}
            <i class="far" [ngClass]="sortDirection === 'desc' ? 'fa-sort-amount-down': 'fa-sort-amount-up'"></i>
          </span>
          <i class="far fa-angle-down"></i>
          <mat-menu #sortBy="matMenu">
            <button mat-menu-item [matMenuTriggerFor]="sortDirectionMenu"
              (mouseover)="activeSorting='Importance'">Importance</button>
            <button mat-menu-item [matMenuTriggerFor]="sortDirectionMenu"
              (mouseover)="activeSorting='Date'">Date</button>
          </mat-menu>
          <mat-menu #sortDirectionMenu="matMenu">
            <span mat-menu-item (click)="sortNotifications('desc')">
              {{activeSorting === 'Importance'? 'Highest first': 'Latest first'}}
            </span>
            <span mat-menu-item (click)="sortNotifications('asc')">
              {{activeSorting === 'Importance'? 'Lowest first': 'Oldest first'}}
            </span>
          </mat-menu>
        </div>
      </div>
    </div>
    <div
      class="col-10 col-sm-5 mt-1 mt-sm-0 ml-sm-0 px-sm-0 px-md-2 d-flex align-items-center justify-content-center justify-content-sm-end">
      <div class="input-container">
        <input type="text" class="border search-filter p-2" (keyup.enter)="filterByCompanyName($event.target.value); $event.target.value=''"
          placeholder="Search" maxlength="16" />
        <span class="search-icon"><i class="far fa-search"></i></span>
      </div>
      <div role="button" class="border border-rounded-05 mx-3 mx-md-4 p-2 min-width-fit" [matMenuTriggerFor]="showOptions">
        <span class="mr-2">Show: {{showReadNotifications}}</span>
        <i class="ml-1 far fa-angle-down"></i>
        <mat-menu #showOptions="matMenu">
          <button mat-menu-item highlighted (click)="filterMarkedReadNotifications(false)">Unread</button>
          <button mat-menu-item (click)="filterMarkedReadNotifications(true)">Read</button>
          <button mat-menu-item (click)="filterMarkedReadNotifications(null)">All</button>
        </mat-menu>
      </div>
      <div class="ellipsis-menu mr-sm-1 " [matMenuTriggerFor]="ellipsisMenu">
        <span role="button" class="btn util-button px-1 py-0"><i
            class="far fa-ellipsis-v"></i></span>        
        <mat-menu #ellipsisMenu="matMenu">
          <button mat-menu-item data-toggle="modal" data-target="#confirmMarkAllAsRead"><i
              class="far fa-clipboard-check"></i> Mark all my notifications as read</button>
              <!-- TODO: mark current results as read -->
          <!-- <button mat-menu-item data-toggle="modal" data-target="#confirmMarkAllAsRead"><i class="far fa-poll-h"></i> Mark current results as read</button> -->
          <a mat-menu-item href="/grow/subscriptions" class="text-decoration-none"><i class="far fa-cog"></i> Configure
            Zint Grow</a>
          <a mat-menu-item href="/notifications" class="text-decoration-none"><i class="far fa-history"></i> Old Zint Grow UI</a>
        </mat-menu>
      </div>
    </div>
    <div *ngIf="isActiveFiltersVisible()" class="col-12 active-filters mt-2">
      <i class="far fa-filter"></i>
      <span class="px-1" [ngClass]="getObjectKeys(urlQueryParams)?.length ? 'text-primary font-weight-bold' : null" >{{getObjectKeys(urlQueryParams)?.length ? 'Filter applied!' : 'Filtering by:'}}</span>
      <ng-container *ngFor="let filterName of getObjectKeys(activeFilters)">
        <ng-container *ngFor="let filter of activeFilters[filterName]">
          <span role="button" class="badge-filter" [ngClass]="getFilterBackground(filterName)"
            matTooltip="click to remove" (click)="removeBadgeFromFilters(filterName, filter)">
            <i class="fal fa-times-circle fa-xs" [style.color]="'var(--zint-grey-900)'"></i>
            {{filterName === 'company' ? filter : filter.name}}
          </span>
        </ng-container>
      </ng-container>
      <span role="button" class="reset-all" (click)="resetFilters()"><i class="far fa-undo fa-xs"></i>Clear all</span>
    </div>
  </section>
  <div *ngIf="isLoading.loadingNotifications" class="text-center my-5">    
    <h1 class="loader">Zint Gr&nbsp;&nbsp;w</h1>
  </div>
  <section *ngIf="!isLoading.loadingNotifications" class="container-fluid">
    <div *ngIf="resultCount" class="d-flex justify-content-end px-1 mb-1">      
      <span class="align-self-center">Page {{extraFilterParams.pageNumber}} of {{totalPages}}</span>
      <div *ngIf="nextPage || prevPage" class="mx-2">
        <button role="button" [disabled]="extraFilterParams.pageNumber === 1" class="btn btn-sm util-button mr-1" matTooltip="first page"
          (click)="loadFirstOrLastPage('first')"><i class="far fa-chevron-double-left"></i></button>
        <span role="button" class="btn btn-sm util-button mr-1" matTooltip="previous page"
          (click)="loadNotificationPage('previous')">
          <i class="far fa-chevron-left"></i></span>
        <span role="button" class="btn btn-sm util-button mr-1" matTooltip="next page"
          (click)="loadNotificationPage('next')">
          <i class="far fa-chevron-right"></i></span>
        <button role="button" [disabled]="extraFilterParams.pageNumber === totalPages" class="btn btn-sm util-button"
          matTooltip="last page" (click)="loadFirstOrLastPage('last')"><i
            class="far fa-chevron-double-right"></i></button>
      </div>      
        <span role="button" class="btn btn-sm util-button" (click)="toggleAllPanels()">
          <i class="fad fa-stream"></i>
          {{isAnyPanelExpanded ?
          "Collapse all" : "Expand all"}}</span>      
    </div>
    <mat-accordion [togglePosition]="'before'" multi #accordion="matAccordion">
      <mat-expansion-panel *ngFor="let groupData of notificationsData" #topLevelPanel="matExpansionPanel" class="mb-2"
        (opened)="isAnyPanelExpanded = true" (closed)="isAnyPanelExpanded = false">               
        <mat-expansion-panel-header class="shadow-sm">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <img *ngIf="groupData.companyUrl" class="company-logo" [src]="getLogo(groupData.companyUrl)" />
              <span *ngIf="!groupData.companyUrl"
                class="logo-text">{{createLogoFromInitials(groupData.companyName)}}</span>
              <div>
                <a href="/company/{{groupData.companyNumber}}" target="_blank"
                  matTooltip="view company on Zint" matTooltipPosition="above" (click)="$event.stopPropagation()">                
                  <h4>{{groupData.companyName || 'Company Name'}}</h4>
                </a>
                <span *ngIf="groupData.sicCodeDescription" class="d-block">{{groupData.sicCodeDescription}}</span>
                <span class="d-block">Revenue: {{groupData.revenue |
                  companyDataPipe: "Revenue": null}}
                </span>
                <small class="font-italic text-secondary">User Tracking: {{getTrackingUser(groupData)}}</small>
              </div>
            </div>
            <div class="d-flex flex-column flex-sm-row align-items-center">
              <button class="btn util-button px-1" matTooltip="toggle company as read/unread" (click)="markAllOrCompanyAsRead(groupData.id); $event.stopPropagation()"><i class="far" [ngClass]="markedAsReadCompanies[groupData.id] === true ? 'fa-check-double': 'fa-check'"></i></button>
              <div *ngIf="!topLevelPanel.expanded" class="mx-2">
                <priority-dots matTooltip="priority" 
                  [importance]="groupData.highestImportance" [shownInTopPanel]="!topLevelPanel.expanded"></priority-dots>
              </div>
            </div>
          </div>
        </mat-expansion-panel-header>
        <div class="d-flex flex-row-reverse mt-1 nested-panel">
          <span role="button" class="nested-toggle-button" (click)="toggleNestedPanels(groupData.id)"><i
              class="far fa-compress"></i> {{nestedPanelStates[groupData.id] ?
            "Collapse " : "Expand "}}all events</span>
        </div>

        <mat-expansion-panel *ngFor="let eventSourceType of groupData.eventSourceTypes"
          [expanded]="nestedPanelStates[groupData.id]" [togglePosition]="'before'" class=" nested-panel padding-reset">
          <mat-expansion-panel-header class="shadow-sm">
            <div class="w-100 d-flex justify-content-between align-items-center">
              <div class="d-flex">
                <span class="event-icon">
                  <i [ngClass]="getEventIcon(eventSourceType.eventSourceType)" class=""></i>
                </span>
                <h4 class="ml-2">
                  {{eventSourceType.eventSourceTypeFriendlyName}}
                </h4>
              </div>              
              <div class="">{{eventSourceType.notificationGroupsTotalCount <= eventSourceTypeDisplayCounts[eventSourceType.id] ? eventSourceType.notificationGroupsTotalCount : eventSourceTypeDisplayCounts[eventSourceType.id]}} of {{eventSourceType.notificationGroupsTotalCount}} items</div>
            </div>
          </mat-expansion-panel-header>

          <div class="table-container">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th></th> <!-- timeline col- leave empty -->
                  <th class="">
                    <div class="row flex-nowrap">
                      <span class="col-3 col-xl-2">Priority:</span>
                      <span class="col-5 col-xl-6">Details:</span>
                      <span class="col-4 col-xl-4">Comment:</span>
                    </div>
                  </th>
                  <th><span>Category:</span></th>
                  <th><span>Action:</span></th>
                </tr>
              </thead>
              <tbody>
                <!-- for each notification_groups --> 
                 <ng-container *ngFor="let notificationGroup of eventSourceType.notificationGroups; index as rowIndex; last as isLast; trackBy: trackNotificationGroup">
                    
                  <ng-container *ngIf="rowIndex < eventSourceTypeDisplayCounts[eventSourceType.id]">
                    <tr class="bg-card" id="notificationGroup.eventUuid">
                      
                      <td class="timeline" [ngClass]="{'last-time' : hideTimelineThread(eventSourceType.notificationGroupsTotalCount, rowIndex, isLast)}"><span class="timestamp">
                          
                        <b class="">{{ notificationGroup.latestTimestamp | date: 'dd' }}</b> {{
                        notificationGroup.latestTimestamp | date: 'MMM yy' }}
                      </span>
                    </td>

                    <!-- for each notifications -->
                    <td class="">
                      <ng-container *ngFor="let eachNotification of notificationGroup.notifications">
                        <event-details [groupImportance]="notificationGroup.groupImportance"
                          [eachNotification]="eachNotification"></event-details>
                      </ng-container>
                    </td>

                    <td class="">
                      <div class="text-break"><span class="cat-badge">{{notificationGroup.notifications[0].ruleSetName
                          || 'no data'}}</span></div>
                    </td>

                    <td class="">                     
                      <event-actions [notificationGroup]="notificationGroup" [isActionableByUser]="currentUserEmail === notificationGroup.email"></event-actions>
                    </td>   
                                      
                    </tr>
                    <tr *ngIf="isLoadMoreButtonVisible(rowIndex, eventSourceType.id, eventSourceType.notificationGroupsTotalCount)">
                    <td colspan="8" class="py-0 my-0"><span role="button" class="load-more" (click)="loadMoreNotificationRows(eventSourceType.id, rowIndex);">LOAD MORE <i
                      class="fas fa-long-arrow-down"></i></span></td>
                    </tr>  
                  </ng-container>
                </ng-container> 
                
              </tbody>
            </table>
          </div>
        </mat-expansion-panel>
      </mat-expansion-panel>
    </mat-accordion>
  </section>
</main>



<div class="modal fade" id="confirmMarkAllAsRead" tabindex="-1" role="dialog">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="confirmMarkAllAsReadLabel">Mark all your notifications as read</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <p class="text-danger text-justify">This will mark <b>ALL</b> <i>&nbsp;your</i> unread notifications as completed <span class="mark">even if filters have been applied.</span></p>        
        <button class="basic-button-base button-dark rounded" (click)="markAllOrCompanyAsRead()" data-dismiss="modal">Confirm</button>
      </div>
    </div>
  </div>
</div>