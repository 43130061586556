<div class="row" [ngClass]="markedAsRead ? 'read-notifications' : null">
    <div class="col-md-8">
        <h5>
            Corporate Structure Changes
        </h5>
        <hr>
        <div #highlight *ngFor="let notification of notifications; let i = index">
            <ng-container *ngIf="notification.event_metadata.type_of_change == 'sibling' && i == 0">
                {{notification.event_metadata.child_node_name}} was {{notification.event_metadata.change_type}} as a sibling company with shared parent {{notification.event_metadata.parent_node_name}}
                <div notification-search-term [notification]="notification"></div>
            </ng-container>
            <ng-container *ngIf="notification.event_metadata.type_of_change == 'child'">
                {{notification.event_metadata.child_node_name}} was {{notification.event_metadata.change_type}} as a child of {{notification.event_metadata.parent_node_name}}
                <div notification-search-term [notification]="notification"></div>
            </ng-container>
            <ng-container *ngIf="notification.event_metadata.type_of_change == 'parent'">
                {{notification.event_metadata.parent_node_name}} was {{notification.event_metadata.change_type}} as a parent of {{notification.event_metadata.child_node_name}}
                <div notification-search-term [notification]="notification"></div>
            </ng-container>
        </div>
        <div class="row small-margin-top">
            <div class="col-md-12">
                <ng-container *ngIf="notifications && notifications.length > 0">
                    <ng-container *ngIf="notifications[0].event_metadata.child_company_number">
                        <a href="/company/{{notifications[0].event_metadata.child_company_number}}" target="_blank" class="zint-muted-href">
                            <span class="badge badge-link">
                                <i class="fal fa-external-link"></i>
                                &nbsp;View Child
                            </span>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="notifications[0].event_metadata.parent_company_number">
                        <a href="/company/{{notifications[0].event_metadata.parent_company_number}}" target="_blank" class="zint-muted-href">
                            <span class="badge badge-link">
                                <i class="fal fa-external-link"></i>
                                &nbsp;View Parent
                            </span>
                        </a>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="align-self-center" right-image
        [imageLink]="'https://images.unsplash.com/photo-1664527184222-420bb0fec61a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1332&q=80'"
        ></div>
</div>
