<div *ngFor="let customColumn of inputParams.customColumns" class="small-margin-bottom">
    <ng-container *ngIf="customColumn[inputParams.filterOnAttribute] == inputParams.equalsOrNot">
        <span class="xsmall-margin-bottom">{{ customColumn.name }}:</span>
        <div *ngIf="customColumn.is_option_column || customColumn.column_type == 'User'">
            <select class="custom-select custom-column-select" name="customColumnDropdownInput{{ customColumn.id }}" [(ngModel)]="customColumn.dropdownInput" (change)="customColumnDropDownChanged(customColumn)">
                <option value=""></option>
                <option value="specificValues" selected="selected">Specific Values</option>
                <option value="isSet">Is set</option>
                <option value="isNotSet">Is not set</option>
                <ng-container *ngIf="customColumn.column_type == 'User'">
                    <option value="isMe">Is set to me</option>
                    <ng-container *ngIf="customColumn.is_lockable">
                        <option value="lockState">Lock State</option>
                    </ng-container>
                </ng-container>
            </select>
            <ng-container *ngIf="customColumn.column_type === 'User' && customColumn.is_lockable  && customColumn.dropdownInput && customColumn.dropdownInput !== 'isNotSet'">
                <select class="custom-select custom-column-select" name="customColumnLockFilter{{ customColumn.id }}" [(ngModel)]="customColumn.lockFilter">
                    <option value="" selected="selected"></option>
                    <option value="unlocked">Unlocked</option>
                    <option value="locked">Locked</option>
                </select>
            </ng-container>
            <div class="form-group select-table overflowing-table xsmall-margin-top" style="padding-top: 7px" *ngIf="customColumn.dropdownInput == 'specificValues'">
                <div *ngFor="let option of customColumn.options">
                    <div>
                        <label>
                            <input type="checkbox"
                                   [attr.name]="'customColumn' + customColumn.id +  option.value"
                                   [ngModelOptions]="{standalone: true}"
                                   value="{{option.id}}"
                                   [(ngModel)]="option.checked"/>
                        </label>
                        <span>&nbsp;&nbsp;{{option.value}}</span>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="!customColumn.is_option_column">
            <div *ngIf="customColumn.column_type == 'Text'">
                <select class="custom-select custom-column-select" name="customColumnDropdownInput{{ customColumn.id }}" [(ngModel)]="customColumn.dropdownInput" (ngModelChange)="customColumnDropDownChanged(customColumn)">
                    <option value=""></option>
                    <option value="exact" selected="selected">Exact</option>
                    <option value="notEqual" selected="selected">Not Equal</option>
                    <option value="contains">Contains</option>
                    <option value="notContains">Does not contain</option>
                    <option value="isSet">Is set</option>
                    <option value="isNotSet">Is not set</option>
                </select>
                <input *ngIf="['exact', 'contains', 'notContains', 'notEqual'].indexOf(customColumn.dropdownInput) >= 0" type="text" name="customColumnBoxInput{{ customColumn.id }}" class="form-control custom-column-standard-input" [(ngModel)]="customColumn.boxInput" (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)"><br>
            </div>

            <div *ngIf="customColumn.column_type == 'Number'">
                <select class="custom-select custom-column-select" name="customColumnDropdownInput{{ customColumn.id }}" [(ngModel)]="customColumn.dropdownInput" (ngModelChange)="customColumnDropDownChanged(customColumn)">
                    <option value=""></option>
                    <option value="exact" selected="selected">Exact</option>
                    <option value="greaterThan">Greater Than</option>
                    <option value="lessThan">Less Than</option>
                    <option value="between">Between</option>
                    <option value="isSet">Is set</option>
                    <option value="isNotSet">Is not set</option>
                </select>
                <input *ngIf="['exact', 'greaterThan', 'lessThan'].indexOf(customColumn.dropdownInput) >= 0" name="customColumnBoxInput{{ customColumn.id }}" type="number" class="form-control custom-column-standard-input" [(ngModel)]="customColumn.boxInput" (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                <ng-container *ngIf="customColumn.dropdownInput == 'between'">
                    <input name="customColumnBoxInputLower{{ customColumn.id }}" type="number" class="form-control custom-column-narrow-input" [(ngModel)]="customColumn.boxInputLower" placeholder="lower" (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                        &nbsp;&nbsp; - &nbsp;&nbsp;
                    <input name="customColumnBoxInputUpper{{ customColumn.id }}" type="number" class="form-control custom-column-narrow-input" [(ngModel)]="customColumn.boxInputUpper" placeholder="upper" (keyup.enter)="testIfSearchCompaniesOnEnterPress($event)">
                </ng-container>
                <br>
            </div>


            <div *ngIf="customColumn.column_type == 'Date'">
                <select class="custom-select custom-column-select" name="customColumnDropdownInput{{ customColumn.id }}" [(ngModel)]="customColumn.dropdownInput" (ngModelChange)="customColumnDropDownChanged(customColumn)">
                    <option value=""></option>
                    <option value="exact" selected="selected">Exact</option>
                    <option value="greaterThan">Greater Than</option>
                    <option value="lessThan">Less Than</option>
                    <option value="between">Between</option>
                    <option value="isSet">Is set</option>
                    <option value="isNotSet">Is not set</option>
                </select>
                <ng-container *ngIf="['exact', 'greaterThan', 'lessThan'].indexOf(customColumn.dropdownInput) >= 0">
                    <mat-form-field>
                        <input matInput [value]="customColumn.boxInput" (dateInput)="updateBoxInput($event, customColumn)" name="customColumnBoxInput{{customColumn.id}}" id="customColumnBoxInput{{customColumn.id}}" [matDatepicker]="datepickerRef" disabled>
                        <mat-datepicker-toggle matSuffix [for]="datepickerRef"></mat-datepicker-toggle>
                        <mat-datepicker #datepickerRef disabled="false"></mat-datepicker>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="customColumn.dropdownInput == 'between'">
                    <br>
                    <mat-form-field>
                        <input matInput [value]="customColumn.boxInputLower" (dateInput)="updateLowerBoxInput($event, customColumn)" [max]="customColumn.boxInputUpper || null" name="customColumnBoxInput{{customColumn.id}}" id="customColumnBoxInput{{customColumn.id}}" [matDatepicker]="datepickerRef" placeholder="lower" disabled>
                        <mat-datepicker-toggle matSuffix [for]="datepickerRef"></mat-datepicker-toggle>
                        <mat-datepicker #datepickerRef disabled="false"></mat-datepicker>
                    </mat-form-field>
                </ng-container>
                <ng-container *ngIf="customColumn.dropdownInput == 'between'">&nbsp;&nbsp; - &nbsp;&nbsp;</ng-container>
                <ng-container *ngIf="customColumn.dropdownInput == 'between'">
                    <mat-form-field>
                        <input matInput [value]="customColumn.boxInputUpper" (dateInput)="updateUpperBoxInput($event, customColumn)" [min]="customColumn.boxInputLower || null" name="customColumnBoxInput{{customColumn.id}}" id="customColumnBoxInput{{customColumn.id}}" [matDatepicker]="datepickerRef" placeholder="upper" disabled>
                        <mat-datepicker-toggle matSuffix [for]="datepickerRef"></mat-datepicker-toggle>
                        <mat-datepicker #datepickerRef disabled="false"></mat-datepicker>
                    </mat-form-field>
                </ng-container>
            </div>
        </div>
    </ng-container>
</div>
