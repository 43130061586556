<div *ngIf="eachNotification" class="row align-items-center mb-1 flex-nowrap">
  <div class="col-3 col-xl-2 pr-0 min-width-fit">
    <priority-dots [importance]="groupImportance"></priority-dots>
  </div>
  <div class="col-5 col-xl-6 d-flex align-items-center justify-content-between px-1">
    <div class="">
      <div *ngIf="eventDetailsData" class="p-2 border border-rounded-05 d-inline-flex flex-column text-break event-details"
        [innerHTML]="eventDetailsData" [matTooltip]="detailsTooltip" matTooltipPosition="before" 
        data-toggle="modal" data-target="accountsSummaryModal"
        (click)="openModal()"
        >
      </div>
      <div *ngIf="extraDetails" class="text-break" [innerHTML]="extraDetails | safe: 'html'"></div>
    </div>
    <div *ngIf="zintLink" class="ml-2"><a [href]="zintLink" target="_blank" class="text-reset" [matTooltip]="zintLinkTooltip"><i class="fas fa-external-link"></i></a></div>
  </div>
  <div class="col-4 col-xl-4"><span class="comment">{{eachNotification.ruleDescription || ''}}</span></div>
</div>

<!-- summary modal -->
<div class="modal fade" [id]="'accountsSummaryModal__' + randomId" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
        <div *ngIf="accountsSummary" class="modal-content">    
            <ai-accounts-summaries [summariesYearAccount]="summaryYearAccountId" [aiAccountsSummaries]="accountsSummary"
                [isInModal]="true">
            </ai-accounts-summaries>        
        </div>
    </div>
    
</div>