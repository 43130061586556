import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'search-parameters',
  templateUrl: './search-parameters.component.html',
  styleUrls: ['./search-parameters.component.css'],
})
export class SearchParametersComponent implements OnInit {
  @Output() onClearSearchParam: EventEmitter<any> = new EventEmitter();

  clearParameterButtonWasClicked(clickedEntry): void {
    this.onClearSearchParam.emit([clickedEntry]);
  }

  @Input()
  inputParams: any = {
    searchTags: Array,
  };

  constructor() {}

  ngOnInit() {}
}
