import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { publicMethods, baseUrl, IGrowNotificationsParams } from '../globals';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import { throwError } from 'rxjs';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class ZintGrowService {
  constructor(
    private http: HttpClient,
    private pubMethods: publicMethods,
    private auth: AuthenticationService,
    private cookieService: CookieService
  ) {}

  isLoading = {
    loadingNotifications: false,
    loadingCompanyNameContains: false,
    loadingAISummary: false,
    loadingEmailSettings: false,
  };

  filterQueryParams: string = '';

  getUserRuleNotificationGroups(
    page,
    per_page,
    minNotificationGroupId,
    maxNotificationGroupId,
    companyIds,
    eventSourceTypes,
    selectedRuleSetId,
    showCompleted: boolean | null,
    sortBy,
    sortDirection,
    userProfileIds
  ): Observable<any> {
    this.isLoading['loadingNotifications'] = true;
    let params = new HttpParams()
      .set('page', page)
      .set('perPage', per_page)
      .set('minNotificationGroupId', minNotificationGroupId)
      .set('maxNotificationGroupId', maxNotificationGroupId)
      .set('companyIds', this.pubMethods.stringifySetForGetParams(companyIds))
      .set(
        'eventSourceTypes',
        this.pubMethods.stringifySetForGetParams(eventSourceTypes)
      )
      .set('selectedRuleSetId', selectedRuleSetId)
      .set('sortBy', sortBy)
      .set('sortDirection', sortDirection)
      .set(
        'userProfileIds',
        this.pubMethods.stringifySetForGetParams(userProfileIds)
      );

    if (showCompleted !== null) {
      params = params.set('isMarkedCompleted', showCompleted);
    }

    return this.http
      .post(
        baseUrl + '/get_user_rule_notification_groups/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingNotifications'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getUserRuleNotificationGroups',
            [],
            this.isLoading
          )
        )
      );
  }

  patchAllOrCompanyAsCompleted(
    userId?: string,
    companyId?: number,
    isCompanyMarkedAsRead?: boolean
  ): Observable<HttpResponse<any>> {
    /** booleanValue
     * if userId is passed, this is top-lvl markAllAsRead so one-way true
     * else, this is company lvl and we pass the inverted value
     *  */

    const booleanValue = userId ? true : !isCompanyMarkedAsRead;
    const paramString = userId
      ? `?user_profile_id=${userId}`
      : `?company_id=${companyId}`;

    let body = { isMarkedCompleted: booleanValue };
    return this.http.patch(
      baseUrl + `/companies_with_user_rule_notification_groups/${paramString}`,
      body,
      {
        observe: 'response' as const,
        ...this.auth.helperGetVerifiedPatchDict(),
      }
    );
  }

  toggleNotificationGroupMarkedCompleted(
    notificationGroupIds: any
  ): Observable<any> {
    let params = new HttpParams().set(
      'notificationGroupId',
      notificationGroupIds
    );

    return this.http
      .post(
        baseUrl + '/toggle_completed/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        catchError(error => {
          this.pubMethods.handleError(
            'toggleNotificationGroupMarkedCompleted',
            []
          );
          return throwError(error);
        })
      );
  }

  sendNotificationGroupFeedback(
    notificationGroupId: number,
    isLiked?: boolean,
    text?: string
  ): Observable<any> {
    let params = new HttpParams().set(
      'notificationGroupId',
      notificationGroupId
    );

    if (isLiked !== null) {
      params = params.set('isLiked', isLiked);
    } else if (text) {
      params = params.set('isProblematic', true).set('text', text);
    }
    return this.http
      .post(
        baseUrl + '/notification_feedback/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        catchError(
          this.pubMethods.handleError('sendNotificationGroupFeedback', [])
        )
      );
  }

  getCompaniesWithNotificationsByNameMatch(
    companyNameContains
  ): Observable<any> {
    this.isLoading['loadingCompanyNameContains'] = true;
    let params = new HttpParams().set(
      'companyNameContains',
      companyNameContains
    );
    return this.http
      .post(
        baseUrl + '/get_zint_grow_companies_with_notifications/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingCompanyNameContains'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getCompaniesWithNotificationsByNameMatch',
            [],
            this.isLoading
          )
        )
      );
  }

  getAccountSummaryById(summaryId): Observable<any> {
    const params = new HttpParams().set('summary_id', summaryId);
    this.isLoading['loadingAISummary'] = true;
    return this.http
      .post(
        baseUrl + '/accounts_summary_by_id/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingAISummary'] = false)),
        catchError(
          this.pubMethods.handleError(
            'getAccountSummaryById',
            [],
            this.isLoading
          )
        )
      );
  }

  getZintGrowEmailSettings(): Observable<any> {
    this.isLoading['loadingEmailSettings'] = true;
    return this.http
      .get(
        baseUrl + '/email_subscription_settings',
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(() => (this.isLoading['loadingEmailSettings'] = false)),
        catchError(this.pubMethods.handleError('getZintGrowEmailSettings', []))
      );
  }

  updateZintGrowEmailSettings(
    subscribed: boolean,
    frequency?: string,
    importanceThreshold?: number
  ): Observable<any> {
    this.isLoading['loadingEmailSettings'] = true;
    let params = new HttpParams().set('is_subscribed', subscribed);

    if (frequency && importanceThreshold) {
      params = params
        .set('updatedEmailFrequency', frequency)
        .set('updatedImportanceThreshold', importanceThreshold);
    }

    return this.http
      .post(
        baseUrl + '/email_subscription_settings/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(() => (this.isLoading['loadingEmailSettings'] = false)),
        catchError(
          this.pubMethods.handleError('updateZintGrowEmailSettings', [])
        )
      );
  }

  trackZintGrowUserSource(source: string): Observable<any> {
    const params = new HttpParams().set('source', source);
    return this.http
      .post(baseUrl + '/zint_grow_user_source/', params, {
        ...this.auth.helperGetVerifiedPostDict(),
        observe: 'response',
      })
      .pipe(
        catchError(this.pubMethods.handleError('trackZintGrowUserSource', []))
      );
  }

  getGrowNotifications(params: IGrowNotificationsParams): Observable<any> {
    /**
     * In future, if we need to we can also use the extra params below for filter
     * &is_problematic={boolean} &is_liked={boolean}
     */
    this.isLoading['loadingNotifications'] = true;

    const {
      pageNumber,
      eventSourceTypes,
      companyName,
      ruleSetIds,
      userIds,
      showReadNotifications,
      orderBy,
      orderDirection,
      notificationGroupId,
    } = params;

    const page = pageNumber ? `&page=${pageNumber}` : '';

    const event_source_types = this.appendListQuery(
      eventSourceTypes,
      'event_source_type'
    );

    const category = this.appendListQuery(ruleSetIds, 'category_id');

    const user_profile = this.appendListQuery(userIds, 'user_profile_id');

    const company_name = companyName ? `&company_name=${companyName}` : '';

    const notification_group_id = notificationGroupId
      ? `&notification_group_id=${notificationGroupId}`
      : '';

    const is_marked_completed =
      showReadNotifications !== null
        ? `&is_marked_completed=${showReadNotifications}`
        : '';

    const ordering = orderBy ? '&order=' + orderDirection + orderBy : '';

    let processedQueryString =
      page +
      event_source_types +
      category +
      company_name +
      notification_group_id +
      user_profile +
      is_marked_completed +
      ordering;

    processedQueryString =
      processedQueryString[0] === '&'
        ? processedQueryString.slice(1)
        : processedQueryString;

    this.filterQueryParams = processedQueryString;

    return this.http
      .get<any[]>(
        baseUrl +
          `/companies_with_user_rule_notification_groups/?${this.filterQueryParams}`,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        tap(_ => (this.isLoading['loadingNotifications'] = false)),
        map((data: Record<string, any>) => {
          return {
            ...data,
            results: data.results.map((result: Record<string, any>) => ({
              ...result,
              eventSourceTypes: result.eventSourceTypes.map(
                (eventSourceType: Record<string, any>) => ({
                  ...eventSourceType,
                  id: (Math.random() + 1).toString(36).substring(2),
                })
              ),
            })),
          };
        }),
        catchError(this.pubMethods.handleError('getNotifications', []))
      );
  }

  appendListQuery(params: any[], queryType: string): string {
    if (!params || !params.length) return '';

    const queryString = params
      .map(string => queryType + '=' + string)
      .join('&');

    return `&${queryString}`;
  }

  getUnreadGrowNotificationsCount(): Observable<any> {
    const params = new HttpParams();
    return this.http
      .post(
        baseUrl + '/unread_grow_notifications_count/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        catchError(
          this.pubMethods.handleError('getUnreadGrowNotificationsCount', [])
        )
      );
  }

  removeZGUnreadCountCookie(): void {
    this.cookieService.remove('zgUnreadCountForUser');
  }

  requestZintGrowActivation(): Observable<any> {
    const params = new HttpParams();

    return this.http
      .post(
        baseUrl + '/request_zint_grow_activation/',
        params,
        this.auth.helperGetVerifiedPostDict()
      )
      .pipe(
        catchError(this.pubMethods.handleError('requestZintGrowActivation', []))
      );
  }
}
