import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'import-export-details',
  templateUrl: './import-export-details.component.html',
  styleUrls: ['./import-export-details.component.css'],
})
export class ImportExportDetailsComponent implements OnInit {
  @Input()
  inputParams: any = {
    detailedImportsOrExports: Array,
  };

  constructor() {}

  ngOnInit() {}
}
