<div class="row">
    <div class="col-md-8">
        <h5>
            New Website Seach Term
        </h5>
        <hr>
        <div *ngFor="let notification of notifications">
            <div notification-search-term [notification]="notification"
                [searchTermHTML]="notification.event_metadata.preview"
                [searchTermLink]="'/company/' + companyNumber + '?fullWebsiteSearch=' + notification.event_metadata.search_term"
                [searchTermString]=" ' found on ' + (notification.event_metadata.search_type === 'full' ? 'website' : 'website homepage')">
                [searchTerm]="notification.event_metadata.search_term"
            </div>
        </div>
        <div class="row small-margin-top">
            <div class="col-md-12">
                <a href="{{event_metadata.urls[0]}}" target="_blank">
                    <span class="badge badge-link">
                        <i class="fal fa-external-link"></i>
                        &nbsp;View Website
                    </span>
                </a>
            </div>
        </div>
    </div>
    <div class="align-self-center" right-image
        [imageLink]="'https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2426&q=80'"
        [htmlBelowImage]="htmlBelowImage"></div>
</div>
