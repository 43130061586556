<main class="container">
  <h2>Hubspot Integration</h2>
  <header *ngIf="!hasHubspotAccess" class="alert alert-warning" role="alert">Please contact your superuser or Zint Customer Success for further assistance.</header>
  <section *ngIf="hasAuthorised">
    <div class="row my-4">
      <div class="col">
        <p>You have authorised Zint access to Hubspot. To revoke access, you can do so from your Hubspot
        settings or disable the toggle here.</p>

        <div *ngIf="syncedItems >= 0" class="card" style="width: 22rem;">
          <div class="card-header">
            <h6 class="mb-0">Sync Status</h6>
          </div>
          <div class="card-body">
            <p class="">Last synced on {{lastSyncedDate | date: 'dd MMM y'}}        
            </p>          
            <span class="">Objects synced: {{syncedItems}}</span>          
          </div>
        </div>

      </div>
      <div class="col d-flex flex-column align-items-center py-1">
        <i class="fab fa-hubspot hs-fa-icon"><i class="far fa-thumbs-up hs-auth-success"></i></i>
        <mat-slide-toggle [matTooltip]="hasAuthorised ? 'revoke': 'authorise'" matTooltipPosition="after" [color]="color"
          [checked]="!isRevoking && hasAuthorised" (click)="handleOAuth(!hasAuthorised)"></mat-slide-toggle>
        </div>
      </div>

    </section>    
  <section *ngIf="!hasAuthorised">
    <p>Synchronise data between Zint and Hubspot.</p>
    <div class="row my-4">
      <p class="col">By authorising this integration you agree to the <a href="#termsForHubspotIntegration"
          data-toggle="modal" data-target="#termsForHubspotIntegration">terms and conditions</a> of the Zint-Hubspot
        Integration.</p>
      <div class="col d-flex flex-column align-items-center py-1">
        <i class="fab fa-hubspot hs-fa-icon"></i>
        <mat-slide-toggle [disabled]="!hasHubspotAccess" [matTooltip]="toggleTooltip" matTooltipPosition="after" [color]="color"
          [checked]="hasAuthorised" (click)="handleOAuth(!hasAuthorised)">Authorise</mat-slide-toggle>             
      </div>
    </div>
  </section>
</main>

<div class="modal fade" role="dialog" id="termsForHubspotIntegration" tabindex="-1">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="customColumnEditModalLabel">Terms and Conditions for Hubspot Integration</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <iframe title="Terms and Conditions"
          src="https://docs.google.com/document/d/e/2PACX-1vSZyb90qTDn_8kGdkEbfXf00p6u0ZQ2qMvgPqxBsnnNyfdnrgDZtjTBhnSl8vQBihsjryOXJamqcfAi/pub"></iframe>
      </div>
    </div>
  </div>
</div>

<!-- Confirmation Modal -->
<div class="modal fade" id="confirmRevokeModal" tabindex="-1" role="dialog" aria-labelledby="confirmRevokeModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="confirmRevokeModalLabel">Confirm</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Are you sure you want to revoke Zint access to HubSpot?</p>
        <span class="mark">You will need to re-authorise if you want to use Hubspot Integration in future.</span>
      </div>
      <div class="modal-footer">
        <button type="button" class="basic-button-base button-dark rounded" data-dismiss="modal" (click)="isRevoking=false">Cancel</button>
        <button type="button" class="basic-button-base button-dark rounded" (click)="revokeHubspotAccess()">Revoke</button>
      </div>
    </div>
  </div>
</div>