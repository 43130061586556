import { Component, OnInit } from '@angular/core';
import { CRMIntegrationsService } from '../../../services/crm-integrations.service';
import { ThemePalette } from '@angular/material/core';
import { VisibilityService } from '../../../services/visibility.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommunicationService } from '../../../services/communication.service';
declare var $: any;

@Component({
  selector: 'hubspot',
  templateUrl: './hubspot.component.html',
  styleUrls: ['./hubspot.component.css'],
})
export class HubspotComponent implements OnInit {
  constructor(
    private commService: CommunicationService,
    private crmService: CRMIntegrationsService,
    private visService: VisibilityService,
    private _snackbar: MatSnackBar
  ) {}
  color: ThemePalette = 'primary';
  hasHubspotAccess: boolean = false;
  hasAuthorised: boolean = false;
  hubspotPublicUrl: string = '';
  toggleTooltip: string;
  isRevoking: boolean = false;
  lastSyncedDate: string = '';
  syncedItems: number = null;

  ngOnInit(): void {
    document.title = 'Hubspot';
    this.checkHubspotAccess();
    this.checkExistingHSAuth();
  }

  checkHubspotAccess(): void {
    this.commService.getHubspotAccess().subscribe(hasAccess => {
      this.hasHubspotAccess = hasAccess;
      this.getTooltipText();
    });
  }

  ngAfterViewInit(): void {
    this.visService.getVisibilityChange().subscribe(visibilityChange => {
      //if not already authorised, tab change and revisit, reloads the page.
      if (!this.hasAuthorised && visibilityChange) {
        window.location.reload();
      }
    });
  }

  getTooltipText(): void {
    this.toggleTooltip = this.hasHubspotAccess
      ? this.hasAuthorised
        ? 'revoke'
        : 'authorise'
      : 'disabled';
  }

  checkExistingHSAuth(): void {
    this.crmService.getHubspotAuthStateAndURL().subscribe(res => {
      if (res.success) {
        this.hasAuthorised = true;
        this.lastSyncedDate = res.last_synced;
        this.syncedItems = res.number_of_records_synced;
      }
      if (res.url) {
        this.hubspotPublicUrl = res.url;
      }
    });
  }

  handleOAuth(authorised: boolean): void {
    if (!this.hasHubspotAccess) return;

    if (!authorised) {
      this.isRevoking = true;
      $('#confirmRevokeModal').modal('show');
      return;
    }

    //open a new tab and navigate to the publicUrl
    window.open(this.hubspotPublicUrl, '_blank');
  }

  revokeHubspotAccess(): void {
    this.crmService.revokeHubspotAccess().subscribe(res => {
      if (res.ok) {
        $('#confirmRevokeModal').modal('hide');
        this.hasAuthorised = false;
        this.isRevoking = false;

        this._snackbar.open(
          'Zint no longer has access to your hubspot. In addition, you can uninstall Zint application from your Hubspot connected apps.',
          'X',
          {
            duration: 8000,
          }
        );
      }
    });
  }
}
