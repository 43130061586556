import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompanyClustersComponent } from './company-clusters/company-clusters.component';
import { CompanyDetailComponent } from './company-detail/company-detail.component';
import { LogoutComponent } from './logout/logout.component';
import { LoginRegisterComponent } from './login-register/login-register.component';
import { AccountComponent } from './account/account.component';
import { CrossSellComponent } from './cross-sell/cross-sell.component';
import { UploadCompaniesComponent } from './upload-companies/upload-companies.component';
import { OrganisationManagementComponent } from './organisation-management/organisation-management.component';
import { LearningPortalComponent } from './learning-portal/learning-portal.component';
import { AlertsComponent } from './alerts/alerts.component';
import { PropensityScoreComponent } from './propensity-score/propensity-score.component';
import { CrmIntegrationsComponent } from './crm-integrations/crm-integrations.component';
import { MarketMappingReportComponent } from './market-mapping-report/market-mapping-report.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ManageTeamsComponent } from './manage-teams/manage-teams.component';
import { SalesforceSettingsPageComponent } from './salesforce/salesforce-settings-page/salesforce-settings-page.component';
import { UploadCompaniesV2Component } from './upload-companies-v2/upload-companies-v2.component';
import { SavedSearchesComponent } from './saved-searches/saved-searches.component';
import { ZendeskAuthComponent } from './zendesk-auth/zendesk-auth.component';
import { PurchasePlanComponent } from './purchase-plan/purchase-plan.component';
import { PlanDetailsComponent } from './plan-details/plan-details.component';
import { ZintGrowComponent } from './zint-grow/zint-grow.component';
import { ZintGrowSubscriptionsComponent } from './zint-grow-subscriptions/zint-grow-subscriptions.component';
import { AiPitchBuilderComponent } from './ai-pitch-builder/ai-pitch-builder.component';
import { ManageTerritoriesComponent } from './manage-territories/manage-territories.component';
import { CalendarIntegrationComponent } from './calendar-integration/calendar-integration.component';
import { ManageEmailsComponent } from './manage-emails/manage-emails.component';
import { NotificationsPageComponent } from './zint-grow/notifications-page/notifications-page.component';
import { HubspotComponent } from './hubspot/hubspot/hubspot.component';
import { DownloadLinkComponent } from './download-link/download-link.component';
import { AuthGuard } from '../services/auth.guard';
import { ChatbotComponent } from './chatbot/chatbot.component';

const routes: Routes = [
  { path: 'login', component: LoginRegisterComponent },
  { path: 'register', component: LoginRegisterComponent },
  { path: 'reset_password', component: LoginRegisterComponent },
  { path: 'reset_password_confirm', component: LoginRegisterComponent },
  { path: 'confirm_account', component: LoginRegisterComponent },
  { path: 'request_new_activation_token', component: LoginRegisterComponent },
  { path: 'logout', component: LogoutComponent, canActivate: [AuthGuard] },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'zint-academy', component: LearningPortalComponent },
  {
    path: 'manage-team',
    component: OrganisationManagementComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'upload-companies',
    component: UploadCompaniesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'cross-sell-opportunities',
    component: CrossSellComponent,
    canActivate: [AuthGuard],
  },
  { path: 'alerts', component: AlertsComponent, canActivate: [AuthGuard] },
  {
    path: 'propensity-score',
    component: PropensityScoreComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'crm-integrations',
    component: CrmIntegrationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'market-mapping-report',
    component: MarketMappingReportComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'company/:id',
    component: CompanyDetailComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'company/:id/clusters',
    component: CompanyClustersComponent,
    canActivate: [AuthGuard],
  },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  {
    path: 'manage-organisation',
    component: ManageTeamsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'matcher',
    component: UploadCompaniesV2Component,
    canActivate: [AuthGuard],
  },
  {
    path: 'salesforce',
    component: SalesforceSettingsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'saved-searches',
    component: SavedSearchesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'knowledge-base-auth',
    component: ZendeskAuthComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'purchase-plan',
    component: PurchasePlanComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'plan-details',
    component: PlanDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'grow',
    component: NotificationsPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'grow/subscriptions',
    component: ZintGrowSubscriptionsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'pitch-builder',
    component: AiPitchBuilderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-territories',
    component: ManageTerritoriesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'calendar-integration',
    component: CalendarIntegrationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'manage-emails',
    component: ManageEmailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'notifications',
    component: ZintGrowComponent,
    canActivate: [AuthGuard],
  },
  { path: 'hubspot', component: HubspotComponent, canActivate: [AuthGuard] },
  {
    path: 'download-link/:id',
    component: DownloadLinkComponent,
    canActivate: [AuthGuard],
  },
  { path: 'chatbot', component: ChatbotComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
