import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { publicMethods, IGrowNotificationsParams } from '../../../globals';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ThemePalette } from '@angular/material/core';
import { ZintGrowService } from '../../../services/zint-grow.service';
import { ZintGrowSubscriptionsService } from '../../../services/zint-grow-subscriptions.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { filter, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { DataSharingService } from '../../../services/data-sharing.service';

enum FilterTypeEnums {
  Category = 'category',
  EventType = 'eventType',
  User = 'user',
}
export interface IFilter {
  selected: boolean;
  name: string;
  color?: ThemePalette;
  types?: IFilter[];
  eventType?: string;
  ruleSetId?: string;
  userId?: number;
}

@Component({
  selector: 'notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.css'],
})
export class NotificationsPageComponent implements OnInit {
  @ViewChildren(MatAccordion) allAccordions: QueryList<MatAccordion>;

  isAnyPanelExpanded: boolean;

  isSuperUser: boolean = false;
  superUserEmail: string = '';
  currentUserId: string = '';
  currentUserEmail: string = '';

  defaultFilter: IFilter = {
    name: 'Select all',
    selected: true,
    color: 'primary',
  };

  eventTypeFilter: IFilter = { ...this.defaultFilter };
  categoryFilter: IFilter = { ...this.defaultFilter };
  userFilter: IFilter = { ...this.defaultFilter };

  nextPage = false;
  prevPage = false;
  totalPages = 0;
  notificationsData: any[] = [];
  resultCount: number = 0;
  totalCompaniesCountPerFilters: number = 0;
  companiesCountOnPage: number = 0;

  isAllSelected = { eventType: true, category: true, user: false };

  filterNameToTypeMap = {
    category: 'ruleSet',
    eventType: 'eventSourceType',
    user: 'user',
  };

  activeFilters: Record<string, any> = {
    eventSourceType: [],
    ruleSet: [],
    user: [],
    company: [],
  };

  defaultExtraParams: Record<string, any> = {
    pageNumber: 1,
    orderBy: 'timestamp',
    orderDirection: '-',
    showReadNotifications: false,
  };

  extraFilterParams: Record<string, any>;

  activeSorting: string = 'Date';
  sortDirection: string = 'desc';

  showReadNotifications = 'Unread';

  nestedPanelStates: Record<string, boolean> = {};

  eventSourceTypeDisplayCounts: Record<number, number> = {};

  groupsInitialDisplayCount = 5;

  markedAsReadCompanies: Record<string, any> = {};

  urlQueryParams: Record<string, any> = {};

  constructor(
    private authService: AuthenticationService,
    private zintGrowService: ZintGrowService,
    private zintGrowSubscriptionsService: ZintGrowSubscriptionsService,
    private pubMethods: publicMethods,
    private dataShareStore: DataSharingService,
    private route: ActivatedRoute,
    private router: Router,
    private _snackbar: MatSnackBar
  ) {}

  isLoading = this.zintGrowService.isLoading;

  ngOnInit(): void {
    document.title = 'Grow';
    // get query params
    this.urlQueryParams = this.route.snapshot.queryParams;

    this.extraFilterParams = this.defaultExtraParams;
    this.checkIsSuperUser(this.urlQueryParams);
    this.setEventTypeFilter();
    this.setCategoryFilter();
  }

  removeQueryParamsFromURL(isSource?: boolean): void {
    if (isSource) {
      /**
       * destructure and remove as we can't call Delete directly on the urlQueryParams object as it's immutable in angular
       */
      const { utm_source, ...newParams } = this.urlQueryParams;
      this.urlQueryParams = newParams;
    } else {
      this.urlQueryParams = {};
    }

    this.router.navigate([], {
      queryParams: { ...this.urlQueryParams },
    });
  }

  checkIsSuperUser(urlParams?: Record<string, any>): void {
    this.authService
      .isUserAuthenticated()
      .pipe(
        tap(
          ({
            is_org_superuser,
            is_parent_org_superuser,
            email,
            user_profile_id,
          }) => {
            this.isSuperUser =
              is_org_superuser === true || is_parent_org_superuser === true;
            this.currentUserEmail = email;
            this.currentUserId = user_profile_id;
            if (this.isSuperUser) {
              this.superUserEmail = email;
            }
          }
        ),
        filter(() => this.isSuperUser),
        tap(() => {
          this.setUserFilter();

          this.getNotificationsData({
            userIds: [this.currentUserId],
            ...this.extraFilterParams,
            ...urlParams,
          });
        })
      )
      .subscribe({
        error: () => console.error('Authentication error!'),
        complete: () => {
          if (!this.isSuperUser) {
            this.getNotificationsData({
              ...this.extraFilterParams,
              ...urlParams,
            });
          }
        },
      });
  }

  setEventTypeFilter(): void {
    this.zintGrowSubscriptionsService.getEventSourceTypes().subscribe(data => {
      if (this.pubMethods.isEmptyObjectOrArray(data)) return;

      const types = Object.keys(data).map(key => ({
        name: data[key]['friendly_name'],
        selected: true,
        eventType: key,
      }));

      this.eventTypeFilter = { ...this.eventTypeFilter, types };
    });
  }

  setCategoryFilter(): void {
    this.zintGrowSubscriptionsService.listRuleSets().subscribe(data => {
      if (this.pubMethods.isEmptyObjectOrArray(data)) return;
      const types = data.rule_sets.map(ruleSet => ({
        name: ruleSet.name,
        selected: true,
        ruleSetId: ruleSet.id,
      }));
      this.categoryFilter = { ...this.categoryFilter, types };
    });
  }

  setUserFilter(): void {
    this.zintGrowSubscriptionsService
      .getSuperuserTeamsAndUsers()
      .subscribe(data => {
        if (this.pubMethods.isEmptyObjectOrArray(data)) return;
        const types = data.organisation_users.map(user => ({
          name: user.email,
          selected: user.isOrganisationAdmin,
          userId: user.userId,
        }));
        this.userFilter = { ...this.userFilter, types };
      });
  }

  getNotificationsData(
    filters: IGrowNotificationsParams = {
      ...this.extraFilterParams,
      userIds: this.isSuperUser ? [this.currentUserId] : [],
    }
  ): void {
    this.zintGrowService.getGrowNotifications(filters).subscribe(data => {
      if (this.pubMethods.isEmptyObjectOrArray(data)) return;

      this.resultCount = 0;
      this.nextPage = !!data.next;
      this.prevPage = !!data.previous;
      this.totalPages = data.totalPages;
      this.notificationsData = data.results;
      this.totalCompaniesCountPerFilters = data.count;
      this.companiesCountOnPage = data.results.length;

      if (!this.notificationsData.length) return;

      this.notificationsData.forEach(({ id: companyId, eventSourceTypes }) => {
        this.nestedPanelStates = {
          ...this.nestedPanelStates,
          [companyId]: true,
        };
        this.getResultCount(eventSourceTypes);

        eventSourceTypes.forEach(({ id: eventSourceId }) => {
          this.eventSourceTypeDisplayCounts[eventSourceId] =
            this.groupsInitialDisplayCount;
        });

        //set the companies MarkedAsCompleted map
        this.markedAsReadCompanies = {
          ...this.markedAsReadCompanies,
          [companyId]: eventSourceTypes.every(({ notificationGroups }) =>
            notificationGroups.every(
              ({ isMarkedCompleted }) => isMarkedCompleted === true
            )
          ),
        };
      });

      // expand panels if there is only one result
      this.updatePanelsState();
    });
  }

  updatePanelsState(): void {
    this.isAnyPanelExpanded = this.resultCount === 1;
    if (this.isAnyPanelExpanded) {
      setTimeout(() => {
        this.allAccordions.forEach(acc => acc.openAll());
      });
    }
  }

  getFilterParams(): IGrowNotificationsParams {
    const { eventSourceType, ruleSet, user, company } = this.activeFilters;

    const eventSourceTypes = eventSourceType?.map(type => type.eventType) || [];

    const ruleSetIds = ruleSet?.map(rule => rule.ruleSetId) || [];

    const userIds = user?.length ? user?.map(user => user.userId) : [];

    const companyName = company ? company[0] : '';

    this.removeQueryParamsFromURL();
    const filterParams = {
      eventSourceTypes,
      ruleSetIds,
      companyName,
      userIds,
      ...this.extraFilterParams,
    };

    return filterParams;
  }

  sortNotifications(sortDirection: string): void {
    this.sortDirection = sortDirection;
    const orderBy =
      this.activeSorting === 'Importance' ? 'importance' : 'timestamp';
    const orderDirection = this.sortDirection === 'desc' ? '-' : '';

    this.extraFilterParams = {
      ...this.extraFilterParams,
      orderBy,
      orderDirection,
      pageNumber: 1,
    };

    this.getNotificationsData({
      ...this.getFilterParams(),
    });
  }

  getEventIcon(eventType: string): string {
    return this.pubMethods.renderEventSourceIcon(eventType, 'r');
  }

  getResultCount(eventsArray: Record<string, any>[]): void {
    eventsArray.forEach(event => {
      this.resultCount += event.notificationGroupsTotalCount;
    });
  }

  // ---- Filter checkbox and badges methods ----
  updateAllSelected(filterName: FilterTypeEnums) {
    const filtersMap = {
      [FilterTypeEnums.Category]: this.categoryFilter,
      [FilterTypeEnums.EventType]: this.eventTypeFilter,
      [FilterTypeEnums.User]: this.userFilter,
    };
    const selectedFilter = filtersMap[filterName];

    this.isAllSelected[filterName] =
      selectedFilter.types != null &&
      selectedFilter.types.every(t => t.selected);

    if (!this.isAllSelected[filterName]) {
      this.activeFilters[this.filterNameToTypeMap[filterName]] =
        selectedFilter.types.filter(t => t.selected);
    } else if (this.isAllSelected[filterName]) {
      this.activeFilters[this.filterNameToTypeMap[filterName]] = [];
    }
    this.extraFilterParams = { ...this.extraFilterParams, pageNumber: 1 };
    this.getNotificationsData({ ...this.getFilterParams() });
  }

  someSelected(filterName: string): boolean {
    const filtersMap = {
      [FilterTypeEnums.Category]: this.categoryFilter,
      [FilterTypeEnums.EventType]: this.eventTypeFilter,
      [FilterTypeEnums.User]: this.userFilter,
    };
    const selectedFilter = filtersMap[filterName];

    if (!selectedFilter.types) {
      return false;
    }

    return (
      selectedFilter.types.filter(t => {
        t.selected;
      }).length > 0 && !this.isAllSelected[filterName]
    );
  }

  selectAll(selected: boolean, filterName: FilterTypeEnums): boolean {
    this.isAllSelected[filterName] = selected;
    this.activeFilters[this.filterNameToTypeMap[filterName]] = [];

    const filtersMap = {
      [FilterTypeEnums.Category]: this.categoryFilter,
      [FilterTypeEnums.EventType]: this.eventTypeFilter,
      [FilterTypeEnums.User]: this.userFilter,
    };
    const selectedFilter = filtersMap[filterName];

    if (selectedFilter.types == null) {
      return;
    }
    selectedFilter.types.forEach(t => (t.selected = selected));
    this.updateAllSelected(filterName);
  }

  getObjectKeys(obj: Record<string, any>): string[] {
    return Object.keys(obj);
  }

  isActiveFiltersVisible(): boolean {
    if (this.getObjectKeys(this.urlQueryParams).length) return true;

    return Object.values(this.activeFilters).some(arr => arr.length > 0);
  }

  removeBadgeFromFilters(
    filterName: string,
    filterObj: Record<string, any>
  ): void {
    if (filterName === 'company') {
      this.activeFilters[filterName] = [];
      this.getNotificationsData({ ...this.getFilterParams() });
    }

    const index = this.activeFilters[filterName].indexOf(filterObj);
    if (index !== -1) {
      const spliced = this.activeFilters[filterName].splice(index, 1);
      this.updateCheckboxes(filterName, spliced[0]);
    }
  }

  updateCheckboxes(filterName, filteredObj: Record<string, any>) {
    const filterNamesMap = {
      ruleSet: 'category',
      eventSourceType: 'eventType',
      user: 'user',
    };

    const filtersMap = {
      [FilterTypeEnums.Category]: this.categoryFilter,
      [FilterTypeEnums.EventType]: this.eventTypeFilter,
      [FilterTypeEnums.User]: this.userFilter,
    };

    const selectedFilterName = filterNamesMap[filterName];
    const selectedFilter = filtersMap[selectedFilterName];

    if (selectedFilter.types == null) {
      return;
    }
    selectedFilter.types.forEach(type =>
      type.name === filteredObj.name ? (type.selected = false) : type.selected
    );

    this.updateAllSelected(selectedFilterName);
  }

  resetFilters(): void {
    this.removeQueryParamsFromURL();
    this.activeFilters = {};

    this.eventTypeFilter.types.forEach(t => (t.selected = true));
    this.categoryFilter.types.forEach(t => (t.selected = true));
    this.userFilter.types?.forEach(
      t => (t.selected = t.name === this.superUserEmail)
    );

    this.isAllSelected = { eventType: true, category: true, user: false };
    this.extraFilterParams = { ...this.extraFilterParams, pageNumber: 1 };

    this.getNotificationsData();
  }

  // ---- checkbox and badges methods end ----

  filterByCompanyName(value) {
    value = value.trim();

    if (!value && this.notificationsData.length) return;

    if (value && value.length < 2) {
      this._snackbar.open('Enter at least 2 characters', 'x', {
        duration: 2000,
      });
      return;
    }
    this.activeFilters['company'] = [value];

    this.extraFilterParams = { ...this.extraFilterParams, pageNumber: 1 };
    this.getNotificationsData({
      ...this.getFilterParams(),
    });
  }

  filterMarkedReadNotifications(showRead: boolean | null) {
    this.showReadNotifications =
      showRead === null ? 'All' : showRead === true ? 'Read' : 'Unread';

    this.extraFilterParams = {
      ...this.extraFilterParams,
      pageNumber: 1,
      showReadNotifications: showRead,
    };

    this.getNotificationsData({
      ...this.getFilterParams(),
    });
  }

  toggleAllPanels(): void {
    if (this.isAnyPanelExpanded === false) {
      this.allAccordions.forEach(acc => acc.openAll());
      this.isAnyPanelExpanded = true;
    } else {
      this.allAccordions.forEach(acc => acc.closeAll());
      this.isAnyPanelExpanded = false;
    }
  }

  toggleNestedPanels(groupDataId: string): void {
    const currentState = this.nestedPanelStates[groupDataId];
    this.nestedPanelStates[groupDataId] = !currentState;
  }

  getFilterBackground(activeFilterName: string): string {
    switch (activeFilterName) {
      case 'eventSourceType':
        return 'event-filter-badge';

      case 'ruleSet':
        return 'ruleSet-filter-badge';

      case 'user':
        return 'user-filter-badge';

      case 'company':
        return 'company-filter-badge';

      default:
        break;
    }
  }

  createLogoFromInitials(companyName: string): string {
    const initials = companyName
      .split(/\s/g)
      .map(chunk =>
        // takes the 2nd letter if 1st if non-alphanumeric e.g bracket
        /\w/gi.test(chunk[0])
          ? chunk[0].toLocaleUpperCase()
          : chunk[1]?.toLocaleUpperCase()
      )
      .join('');

    //only return the even number of letters for the ui consistency
    return initials.length % 2 === 0 ? initials : initials.slice(0, -1);
  }

  getLogo(companyUrl: string): string {
    if (!companyUrl) return;
    return `https://www.google.com/s2/favicons?sz=32&domain=${companyUrl}`;
  }

  getTrackingUser(groupData: Record<string, any>): string {
    return groupData.eventSourceTypes[0]?.notificationGroups[0].email;
  }

  loadNotificationPage(direction: 'previous' | 'next'): void {
    const guardCondition =
      (direction === 'next' && !this.nextPage) ||
      (direction === 'previous' && !this.prevPage);

    if (guardCondition) {
      this._snackbar.open('No page to load.', 'x', {
        duration: 1300,
      });
      return;
    }

    this.extraFilterParams = {
      ...this.extraFilterParams,
      pageNumber:
        direction === 'next'
          ? this.extraFilterParams.pageNumber + 1
          : this.extraFilterParams.pageNumber - 1,
    };

    this.getNotificationsData({
      ...this.getFilterParams(),
    });
  }

  loadFirstOrLastPage(option: 'first' | 'last'): void {
    this.extraFilterParams = {
      ...this.extraFilterParams,
      pageNumber: option === 'first' ? 1 : this.totalPages,
    };

    this.getNotificationsData({
      ...this.getFilterParams(),
    });
  }

  trackNotificationGroup(index: number, notificationGroup: any): number {
    return notificationGroup ? notificationGroup.id : null;
  }

  loadMoreNotificationRows(eventSourceTypeId: string): void {
    this.eventSourceTypeDisplayCounts[eventSourceTypeId] =
      (this.eventSourceTypeDisplayCounts[eventSourceTypeId] ||
        this.groupsInitialDisplayCount) + this.groupsInitialDisplayCount;
  }

  isLoadMoreButtonVisible(
    rowIndex: number,
    eventSourceTypeId: string,
    groupsTotal: number
  ) {
    return (
      rowIndex === this.eventSourceTypeDisplayCounts[eventSourceTypeId] - 1 &&
      this.eventSourceTypeDisplayCounts[eventSourceTypeId] < groupsTotal
    );
  }

  hideTimelineThread(
    totalGroupsCount: number,
    rowIndex: number,
    isLastRow: boolean
  ): boolean {
    /**
     * show/hide last timeline thread based on totalGroupsCount in relation to the initial display count
     * rowIndex and isLastRow come from *ngFor in the template
     */
    if (totalGroupsCount <= this.groupsInitialDisplayCount) return isLastRow;
    if (totalGroupsCount > this.groupsInitialDisplayCount) {
      return rowIndex === this.groupsInitialDisplayCount - 1;
    }
  }

  markAllOrCompanyAsRead(companyId?: number): void {
    /* get correct pageNumber (1 company on last page case)
      if totalCompanies === comCount => pagenum = 1
      elseif comcount is one => pagenum - 1
      else current page number in extraFilters
    */

    const pageNumberParam =
      this.totalCompaniesCountPerFilters === this.companiesCountOnPage
        ? 1
        : this.companiesCountOnPage === 1
          ? this.extraFilterParams.pageNumber - 1
          : this.extraFilterParams.pageNumber;

    this.extraFilterParams = {
      ...this.extraFilterParams,
      pageNumber: pageNumberParam,
    };

    const userId = companyId ? null : this.currentUserId;

    const isCompanyMarkedAsRead = this.markedAsReadCompanies[companyId];

    this.zintGrowService
      .patchAllOrCompanyAsCompleted(userId, companyId, isCompanyMarkedAsRead)
      .subscribe(response => {
        if (response.ok) {
          this.getNotificationsData(this.getFilterParams());

          // share the count to display on the nav bar zg icon
          this.zintGrowService
            .getUnreadGrowNotificationsCount()
            .subscribe(({ count }) => {
              this.dataShareStore.updateZgResultCount(count);
            });
        }
      });
  }
}
